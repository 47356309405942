import "$styles/index.css"
import "$styles/syntax-highlighting.css"
import "$styles/scroll.css"
import PureCounter from "@srexi/purecounterjs"
import $ from "jquery";

window.$ = $;
window.jQuery = $;

const d = document;
var e = {
    isVariableDefined: function (el) {
        return typeof !!el && (el) != 'undefined' && el != null;
    },
    select: function (selectors) {
        return document.querySelector(selectors);
    },
    selectAll: function(selectors) {
        return document.querySelectorAll(selectors);
    }
};
d.addEventListener("DOMContentLoaded", function(event) {

    // Plceholder
    var preloader = d.querySelector('.loading-preloader');
    if (preloader) {
        setTimeout(function() {
            preloader.classList.add('d-none');
        }, 1000);
    }

    // Headroom
    if (d.querySelector('.headroom')) {
        var headroom = new Headroom(document.querySelector(".header-main"), {
            offset: 50,
            tolerance: {
                up: 0,
                down: 0
            },
        });
        headroom.init();
    }

    // Parallax Background
    if (d.querySelector('.parallax')) {
    var par_bg = e.select('.parallax');
    if (e.isVariableDefined(par_bg)) {
        jarallax(e.selectAll('.parallax'), {
            speed: 0.6
        });
    }
    }
    // END: Parallax Background

    // GLightbox
    // var light = e.select('[data-glightbox]');
    // if (e.isVariableDefined(light)) {
    //     var lb = GLightbox({
    //         selector: '*[data-glightbox]',
    //         openEffect: 'fade',
    //         closeEffect: 'fade'
    //     });
    // }

    // Tooltips
    var ToolTipTrigger = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = ToolTipTrigger.map(function(tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    // Popovers
    var PopoverTrigger = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = PopoverTrigger.map(function(popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })


    // Swiper
    var sliderSelector = '.swiper-container',
        defaultOptions = {
            breakpointsInverse: true,
            observer: true
        };

    var nSlider = document.querySelectorAll(sliderSelector);

    [].forEach.call(nSlider, function(slider, index, arr) {
        var data = slider.getAttribute('data-swiper-options') || {};

        if (data) {
            var dataOptions = JSON.parse(data);
        }

        slider.options = Object.assign({}, defaultOptions, dataOptions);

        var swiper = new Swiper(slider, slider.options);

        //console.log(slider.options.autoplay)

        /* stop on hover */
        if (typeof slider.options.autoplay !== 'undefined' && slider.options.autoplay !== false) {
            slider.addEventListener('mouseenter', function(e) {
                swiper.autoplay.stop();
                //console.log('stop')
            });

            slider.addEventListener('mouseleave', function(e) {
                swiper.autoplay.start();
                //console.log('start')
            });
        }
    });

    // Or you can customize it for override the default config.
    // Here is the default configuration for all element with class 'filesizecount'
    new PureCounter({
        // Setting that can't' be overriden on pre-element
        selector: ".purecounter", // HTML query selector for spesific element

        // Settings that can be overridden on per-element basis, by `data-purecounter-*` attributes:
        start: 0, // Starting number [uint]
        end: 100, // End number [uint]
        duration: 2, // The time in seconds for the animation to complete [seconds]
        delay: 10, // The delay between each iteration (the default of 10 will produce 100 fps) [miliseconds]
        once: true, // Counting at once or recount when the element in view [boolean]
        pulse: false, // Repeat count for certain time [boolean:false|seconds]
        decimals: 0, // How many decimal places to show. [uint]
        legacy: true, // If this is true it will use the scroll event listener on browsers
        filesizing: false, // This will enable/disable File Size format [boolean]
        currency: false, // This will enable/disable Currency format. Use it for set the symbol too [boolean|char|string]
        formater: "us-US", // Number toLocaleString locale/formater, by default is "en-US" [string|boolean:false]
        separator: false, // This will enable/disable comma separator for thousands. Use it for set the symbol too [boolean|char|string]
    });

});


var MOMB = {};
$.fn.exists = function() {
    return this.length > 0;
};


/*--------------------
* Menu toogle header
----------------------*/
MOMB.MenuToggleClass = function() {
    $('.navbar-toggler').on('click', function() {
        var toggle = $('.navbar-toggler').is(':visible');
        if (toggle) {
            $('header').toggleClass('header-toggle');
        }
    })
}

/*--------------------
    * Menu Close
----------------------*/
MOMB.MenuClose = function(){
$('.one-page-nav a').on('click', function() {
var toggle = $('.navbar-toggler').is(':visible');
if (toggle) {
    $('.navbar-collapse').collapse('hide');
}
});
}


/* ---------------------------------------------- /*
* Header height
/* ---------------------------------------------- */
MOMB.HeaderHeight = function() {
    var HHeight = $('.header-height').outerHeight()
    var HHeightTop = $('.header-top').outerHeight()
    $('.header-height-bar').css("min-height", HHeight);
}

/* ---------------------------------------------- /*
* Mega Menu
/* ---------------------------------------------- */

MOMB.MegaMenu = function() {
    var mDropdown = $(".px-dropdown-toggle")
    mDropdown.on("click", function() {
        $(this).parent().toggleClass("open-menu-parent");
        $(this).next('.dropdown-menu').toggleClass("show");
        $(this).toggleClass("open");
    });
}


/*--------------------
* Typed
----------------------*/
MOMB.typedbox = function() {
    var typedjs = $('.typed');
    if (typedjs.length > 0) {
        typedjs.each(function() {
            var $this = $(this);
            $this.typed({
                strings: $this.attr('data-elements').split(','),
                typeSpeed: 150, // typing speed
                backDelay: 500 // pause before backspacing
            });
        });
    }
}


/* ---------------------------------------------- /*
* lightbox gallery
/* ---------------------------------------------- */
MOMB.Gallery = function() {
    var GalleryPopup = $('.lightbox-gallery');
    if (GalleryPopup.length > 0) {
        $('.lightbox-gallery').magnificPopup({
            delegate: '.gallery-link',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-fade',
            fixedContentPos: true,
            closeBtnInside: false,
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after CRE current image
            }
        });
    }
    var VideoPopup = $('.video-btn');
    if (VideoPopup.length > 0) {
        $('.video-btn').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    }
}

/*--------------------
    * Wow
----------------------*/
MOMB.wowAni = function() {
    new WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 100,
        mobile: !1,
        live: !0
    }).init();
}
/*--------------------
    * pieChart
----------------------*/
MOMB.pieChart = function () {
    var $Pie_Chart = $('.pie_chart_in');
    if ($Pie_Chart.length > 0) {
        $Pie_Chart.each(function () {
            var $elem = $(this),
                pie_chart_size = $elem.attr('data-size') || "160",
                pie_chart_animate = $elem.attr('data-animate') || "2000",
                pie_chart_width = $elem.attr('data-width') || "6",
                pie_chart_color = $elem.attr('data-color') || "#84ba3f",
                pie_chart_track_color = $elem.attr('data-trackcolor') || "rgba(0,0,0,0.10)",
                pie_chart_line_Cap = $elem.attr('data-lineCap') || "round",
                pie_chart_scale_Color = $elem.attr('data-scaleColor') || "true";
            $elem.find('span, i').css({
                'width': pie_chart_size + 'px',
                'height': pie_chart_size + 'px',
                'line-height': pie_chart_size + 'px',
                'position': 'absolute'
            });
            $elem.easyPieChart({
                size: Number(pie_chart_size),
                animate: Number(pie_chart_animate),
                trackColor: pie_chart_track_color,
                lineWidth: Number(pie_chart_width),
                barColor: pie_chart_color,
                scaleColor: false,
                lineCap: pie_chart_line_Cap,
                onStep: function (from, to, percent) {
                    $elem.find('span.middle').text(Math.round(percent));
                }
            });

        });
    }
}

/*--------------------
    * One Page
----------------------*/
MOMB.one_page = function() {
    //var HHeight = $('.navbar').outerHeight();
    var $one_page_nav = $('.one-page-nav');
    if($one_page_nav.length > 0) {
        $one_page_nav.each(function(){
            $.scrollIt({
            upKey: 38,             // key code to navigate to the next section
            downKey: 40,           // key code to navigate to the previous section
            easing: 'linear',      // the easing function for animation
            scrollTime: 600,       // how long (in ms) the animation takes
            activeClass: 'active', // class given to the active nav element
            onPageChange: null,    // function(pageIndex) that is called when page is changed
            topOffset: -70           // offste (in px) for fixed top navigation
            });
        });
    }
}

// Document on Ready
$(document).ready(function() {
    MOMB.MenuToggleClass(),
    MOMB.MenuClose(),
    MOMB.Gallery(),
    MOMB.HeaderHeight(),
    MOMB.MegaMenu(),
    MOMB.typedbox(),
    MOMB.wowAni(),
    MOMB.pieChart(),
    MOMB.one_page();
});

// Window on Resize
$(window).resize(function() {
    MOMB.HeaderHeight();
});

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

(function(e) {
    "use strict";
    var t = "ScrollIt"
    , n = "1.0.3";
    var r = {
        upKey: 38,
        downKey: 40,
        easing: "linear",
        scrollTime: 600,
        activeClass: "active",
        onPageChange: null,
        topOffset: 0
    };
    e.scrollIt = function(t) {
        var n = e.extend(r, t)
        , i = 0
        , s = e("[data-scroll-index]:last").attr("data-scroll-index");
        var o = function(t) {
            if (t < 0 || t > s)
                return;
            var r = e("[data-scroll-index=" + t + "]").offset().top + n.topOffset + 1;
            e("html,body").animate({
                scrollTop: r,
                easing: n.easing
            }, n.scrollTime)
        };
        var u = function(t) {
            var n = e(t.target).closest("[data-scroll-nav]").attr("data-scroll-nav") || e(t.target).closest("[data-scroll-goto]").attr("data-scroll-goto");
            o(parseInt(n))
        };
        var a = function(t) {
            var r = t.which;
            if (e("html,body").is(":animated") && (r == n.upKey || r == n.downKey)) {
                return false
            }
            if (r == n.upKey && i > 0) {
                o(parseInt(i) - 1);
                return false
            } else if (r == n.downKey && i < s) {
                o(parseInt(i) + 1);
                return false
            }
            return true
        };
        var f = function(t) {
            if (n.onPageChange && t && i != t)
                n.onPageChange(t);
            i = t;
            e("[data-scroll-nav]").removeClass(n.activeClass);
            e("[data-scroll-nav=" + t + "]").addClass(n.activeClass)
        };
        var l = function() {
            var t = e(window).scrollTop();
            var r = e("[data-scroll-index]").filter(function(r, i) {
                return t >= e(i).offset().top + n.topOffset && t < e(i).offset().top + n.topOffset + e(i).outerHeight()
            });
            var i = r.first().attr("data-scroll-index");
            f(i)
        };
        e(window).on("scroll", l).scroll();
        e(window).on("keydown", a);
        e("body").on("click", "[data-scroll-nav], [data-scroll-goto]", function(e) {
            e.preventDefault();
            u(e)
        })
    }
}
)(jQuery)
